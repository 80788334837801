<template>
  <div id="login" @mousemove="mouseMoveFn($event)" v-if="isRe">
    <div class="login-box">
      <div class="login-main">
        <div class="abbSvg">
          <img src="../assets/imgs/login/abb.svg" alt="" />
          <!-- <img style="width: 75px;position: relative;top: 20px;" src="../assets/imgs/logo-color.png" alt="" /> -->
        </div>
        <!-- 标题 -->
        
        <!-- <div class="login-main-title" style="font-family: ABBvoice_WCNSG_Rg;"> ABB 智慧能源平台，欢迎您！</div> -->
        <div class="login-main-title" style="font-family: ABBvoice_WCNSG_Rg;"> ABB智慧能源平台，欢迎您！</div>
        <el-form :model="form" :rules="rules">
          <!-- 邮箱 -->
          <el-form-item prop="username">
            <div class="login-main-input" v-if="currentLoginmode == 'zhanghao'">
              <el-input
                v-model="form.username"
                placeholder="请输入用户名"
              ></el-input>
            </div>
          </el-form-item>
          <!-- 密码 -->
          <el-form-item prop="password">
            <div class="login-main-input" v-if="currentLoginmode == 'zhanghao'">
              <el-input
                :type="cansee ? 'text' : 'password'"
                v-model="form.password"
                placeholder="请输入密码"
                class="Password_input"
              >
                <!-- <i slot="suffix" class="lowcode icon-mima isshowPassword" @click="cansee=!cansee"></i> -->
              </el-input>
              <!-- <div class="remember">
                <el-checkbox v-model="radio"></el-checkbox> 记住密码
              </div> -->
            </div>
          </el-form-item>
        </el-form>
        <!-- 验证 -->
        <!--                <div class="login-main-yanzheng" v-if="currentLoginmode == 'zhanghao'" ref="dragDiv" >-->
        <!--                    <div class="login-main-yanzheng-button" ref="moveDiv" @mousedown="mousedownFn($event)">-->
        <!--                        <i class="lowcode icon-youjiantou1"></i>-->
        <!--                    </div>-->
        <!--                    <span>{{confirmWords}}</span>-->
        <!--                </div>-->
        <!-- 登录按钮 -->
        <!-- <div class="login-main-button" @click="loginBtn" style="background: #fc2121;">登录</div> -->
        <div class="login-main-button" @click="loginBtn">登录</div>
        <!-- <span class="forget">忘记密码?</span> -->
        <!-- 微信登录提示 -->
        <!--                <div class="login-main-zhuce">-->
        <!--                    <span>还没有账号？</span>-->
        <!--                    <span @click="registerBtn">马上注册</span>-->
        <!--                </div>-->
      </div>
      <span class="reserved">Copyright 2022-2023 . All rights reserved</span>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" style="
    position: absolute;
    bottom: 10px;
    margin: auto;
    font-family: ABBvoiceCNSG-Regular;
    font-size: 16px;color: rgba(255, 255, 255, 0.6);letter-spacing: 0;line-height: 20px;font-weight: 400;"></a>
    </div>
    <!-- 轮播图 -->
    <!-- <template>
            <div class="login-banner" :class="{'single-banner': singleBanner}"> -->
    <!-- <el-carousel :interval="5000">
                    <el-carousel-item>
                        <div class="right-banner">
                            <div class="banner-top">
                                <div>
                                    <img src="../assets/imgs/login/light.png" alt="">
                                </div>
                                <div>
                                    <img src="../assets/imgs/login/bowen1.png" alt="">
                                </div>
                            </div>
                            <div class="banner-middle">
                                <img src="../assets/imgs/login/tips.png" alt="">
                            </div>
                            <div class="banner-desc">
                                <img src="../assets/imgs/login/lCodeWord.svg" alt="">
                            </div>
                            <div class="banner-desc-left">
                                <img src="../assets/imgs/login/bowen.png" alt="">
                            </div>
                        </div>
                    </el-carousel-item> -->
    <!-- <el-carousel-item>
                        <div class="right-banner">
                        <img src="../assets/imgs/login/banner1.png" alt="">
                        </div>
                    </el-carousel-item> -->
    <!-- </el-carousel> -->
    <!-- <img src="../assets/imgs/login/newlogin.png" alt="" class="banner-png">
            </div>
        </template> -->
  </div>
</template>

<script>
import Utils from "../../utils";
import {getallData} from "../getCurrent"
export default {
  name: "login",
  data() {
    return {
      jump:false,
      form: {
        username: "",
        password: "",
      },
      phonenumber: "",
      verificationCode: "",
      beginClientX: 0, // 距离屏幕左端距离
      mouseMoveStata: false, // 触发拖动状态  判断
      maxwidth: "", // 拖动最大宽度，依据滑块宽度算出来的
      confirmWords: "向右滑动完成验证", // 滑块文字
      confirmSuccess: false, // 验证成功判断
      currentLoginmode: "zhanghao",
      singleBanner: true, //单banner
      cansee: false,
      radio: false,
      rules: {
        username: [
          { required: true, message: "您必须输入邮箱地址", trigger: "blur" },
        ],
        password: [
          { required: true, message: "您必须输入密码", trigger: "blur" },
        ],
      },
    };
  },
  computed:{
        // 是否完成加载
      isRe() {
          return sessionStorage.getItem('isRe')
      },
      ifjump(){
         return this.$store.state.jump;
      }
  },
  methods: {
    //鼠标按下方法
    mousedownFn(e) {
      e.preventDefault && e.preventDefault(); // 阻止文字选中等 浏览器默认事件
      this.mouseMoveStata = true; // 把触发拖动状态这个阈值打开
      this.beginClientX = e.clientX; // 记录手指移动的距离
    },
    //鼠标拖动方法
    mouseMoveFn(e) {
      if (this.mouseMoveStata) {
        let width = e.clientX - this.beginClientX;
        if (width > 0 && width <= this.maxwidth - 1) {
          this.confirmWords = "";
          document.getElementsByClassName(
            "login-main-yanzheng-button"
          )[0].style.left = width + "px";
          document.getElementsByClassName(
            "login-main-yanzheng"
          )[0].style.background = "rgba(28,116,241,0.15)";
          document.getElementsByClassName(
            "login-main-yanzheng"
          )[0].style.borderColor = "#1C74F1";
          document.getElementsByClassName(
            "login-main-yanzheng-button"
          )[0].style.background = "#1C74F1";
          document.getElementsByClassName(
            "login-main-yanzheng-button"
          )[0].children[0].style.color = "#ffffff";
        } else if (width >= this.maxwidth - 1) {
          document.getElementsByClassName(
            "login-main-yanzheng-button"
          )[0].style.left = this.maxwidth - 1 + "px";
          this.confirmSuccess = true;
          this.confirmWords = "验证成功";
          document.getElementsByClassName(
            "login-main-yanzheng"
          )[0].style.background = "rgba(4,167,145,0.15)";
          document.getElementsByClassName(
            "login-main-yanzheng"
          )[0].style.borderColor = "#04a791";
          document.getElementsByClassName(
            "login-main-yanzheng"
          )[0].children[1].style.color = "#04A791";
          document.getElementsByClassName(
            "login-main-yanzheng-button"
          )[0].style.background = "#04a791";
          document.getElementsByClassName(
            "login-main-yanzheng-button"
          )[0].children[0].style.color = "#ffffff";
          document
            .getElementsByClassName("login-main-yanzheng")[0]
            .removeEventListener("mousemove", this.mouseMoveFn);
          document
            .getElementsByClassName("login-main-yanzheng")[0]
            .removeEventListener("mouseup", this.moseUpFn);
        }
      }
    },
    //鼠标弹起事件
    moseUpFn(e) {
      if (this.currentLoginmode == "zhanghao") {
        this.$nextTick(() => {
          this.mouseMoveStata = false;
          // 获取当前拖拽按钮的偏移量
          var width = Number(
            document
              .getElementsByClassName("login-main-yanzheng-button")[0]
              .style.left.substr(
                0,
                document.getElementsByClassName("login-main-yanzheng-button")[0]
                  .style.left.length - 2
              )
          );
          if (width < this.maxwidth - 1) {
            this.confirmSuccess = false;
            this.confirmWords = "向右滑动完成验证";
            document.getElementsByClassName(
              "login-main-yanzheng-button"
            )[0].style.left = 1 + "px";
            document.getElementsByClassName(
              "login-main-yanzheng"
            )[0].style.background = "#f1f2f5";
            document.getElementsByClassName(
              "login-main-yanzheng"
            )[0].style.borderColor = "#f1f2f5";
            document.getElementsByClassName(
              "login-main-yanzheng-button"
            )[0].style.background = "#ffffff";
            document.getElementsByClassName(
              "login-main-yanzheng-button"
            )[0].children[0].style.color = "#202328";
            document.getElementsByClassName(
              "login-main-yanzheng"
            )[0].children[1].style.color = "#202328";
          }
        });
      }
    },
    initYanzhengFn() {
      this.confirmSuccess = false;
      this.confirmWords = "向右滑动完成验证";
      this.beginClientX = 0;
      this.mouseMoveStata = false;
      if (this.currentLoginmode == "zhanghao") {
        this.$nextTick(() => {
          // 根据滑块宽度计算可拖动最大宽度
          this.maxwidth =
            this.$refs.dragDiv.clientWidth - this.$refs.moveDiv.clientWidth;
          document.getElementsByClassName(
            "login-main-yanzheng-button"
          )[0].style.left = 1 + "px";
          document.getElementsByClassName(
            "login-main-yanzheng"
          )[0].style.background = "#f1f2f5";
          document.getElementsByClassName(
            "login-main-yanzheng"
          )[0].style.borderColor = "#f1f2f5";
          document.getElementsByClassName(
            "login-main-yanzheng-button"
          )[0].style.background = "#ffffff";
          document.getElementsByClassName(
            "login-main-yanzheng-button"
          )[0].children[0].style.color = "#202328";
          document.getElementsByClassName(
            "login-main-yanzheng"
          )[0].children[1].style.color = "#646A74";
        });
      }
    },
    //注册按钮
    registerBtn() {
      this.$router.push("/register");
    },
    //获取用户信息
    getUserInfo() {
      const url = Config.dev_url + "/api-user/users/currentMap";
      this.$axios.get(url, {}).then((res) => {
          //设置默认主题
          if (!!res.data.navStyle) {
            document.documentElement.setAttribute("theme-mode",res.data.navStyle);
          } else {
            document.documentElement.setAttribute("theme-mode", "light");
          }
          if (!!res.data.themeColor) {
            document.documentElement.setAttribute("theme-color",res.data.themeColor);
          } else {
            document.documentElement.setAttribute("theme-color", "#1C74F1");
          }
          // 
          if(res.data.THEMECOLOR == '#1C74F1'){
            document.getElementById('skin').href ='/blue.css'
          }else{
            document.getElementById('skin').href ='/yellow.css'
          }
          
          this.$store.commit("setcurrentuserinfo", res.data);
          this.switchLang(res.data.LANG || 'CH')
          var item = {
            code: "M0138",
            enname: "defaultIndex",
            id: "1563",
            menutype: "4",
            name: "总览",
            pcode: "-1",
            url: "/defaultIndex"
          }
          this.$store.commit("setCurrentSelectMenuInfo",item)
          this.$store.commit("setdefaultActiveIndex",item.id)
          this.$router.push("/defaultIndex");
        })
        .catch((err) => {});
    },

     switchLang(val){
        this.currentLang = val;
        sessionStorage.setItem('locale',val)
        this.$i18n.locale = val;
        //向子应用传递数据
        this.$actions.setGlobalState({
            currentLocale : val,
        });
    },

    //登录初始化
    loginInitFun() {
      //清除本地缓存
      // sessionStorage.clear();
      this.$store.commit("setcurrentuserinfo", {});
      this.$store.commit("setallMenuData", []);
      this.$store.commit("setViewMenuData", []);
      this.$store.commit("setdefaultActiveIndex", ""); //选中菜单id
      this.$store.commit("setallMenuLoading", false); //左侧菜单加载状态
    },
    //登录按钮
    loginBtn() {
      this.loginInitFun();
      console.log("登陆了");
      if (this.currentLoginmode == "zhanghao") {
        if (this.form.username == "") {
          this.$message({
            message: "请输入账号进行登录",
            type: "warning",
          });
          // this.initYanzhengFn();
          return;
        } else if (this.form.password == "") {
          this.$message({
            message: "请输入密码进行登录",
            type: "warning",
          });
          // this.initYanzhengFn();
          return;
        }
        // else if(!this.confirmSuccess){
        //     this.$message({
        //         message: '请向右滑动完成验证',
        //         type: 'warning'
        //     });
        //     this.initYanzhengFn();
        //     return
        // }
        let param = new URLSearchParams();
        param.append("username", this.form.username);
        param.append(
          "password",
          Utils.encrypt(this.form.password, "hAw6eqnFLKxpsDv3")
        );
        // param.append("password", this.password);
        var url = Config.dev_url + "/api-auth/oauth/user/token";
        this.$axios({
          method: "post",
          url: url,
          data: param,
          headers: { client_id: "webApp", client_secret: "webApp" },
        })
          .then((res) => {
            console.log(res);
            if (res.data.access_token) {
              sessionStorage.setItem(
                "application_access_token",
                res.data.access_token
              );
              sessionStorage.setItem(
                "application_expires_in",
                res.data.access_token
              );
              sessionStorage.setItem(
                "application_refresh_token",
                res.data.access_token
              );
              getallData(Config,this,'defaultIndex',"currentDataAll")
              this.getCommonLanguageList()
            }
          })
          .catch((err) => {
            // this.$message.warning(err.msg);
          });
      }
    },
    // 禁止回退
    forbidBack() {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", () => {
        history.pushState(null, null, document.URL);
      });
    },
    // 销毁禁止回退
    removeForbidBack() {
      window.removeEventListener(
        "popstate",
        () => {
          history.pushState(null, null, document.URL);
        },
        false
      );
    },

    // 获取通用组件国际化表
    getCommonLanguageList(){
        let url=Config.dev_url + "/api-user/sysPageLang/selectSysPageLang"
        let data={
            page:1,
            limit:500,
            menukey:'common',
            sourceid:'',
            controlid:'',
            componenttype:''
        }
        this.$axios.post(url,data).then(res=>{
         
          if(res.data.code==0){
              this.jump = true
              let arr = res.data.data || []

              
              let obj ={...this._i18n._vm._data.messages}
              console.log(this._i18n._vm._data.messages)
            
              arr.forEach(item => {
                  for(let key in item){
                      if(key != 'componenttype' && key != 'menukey' && key.indexOf('id') == '-1' && !obj[key]){
                          obj[key] = {}
                      }
                  }
              });
              arr.forEach(item=>{
                  for(let key in obj){
                      obj[key][item.controlid] = item[key]
                  }
              })
              
              console.log(obj)
              sessionStorage.setItem( 'commonLang', JSON.stringify(obj))
              // this.$store.commit('setcommonLanguageList', obj);
          }
      })
    },
  },
  created() {
    
    this.$store.commit("setmenuAndDrawer", false);
    this.$store.commit("setdashboardOptions",{});
    this.$store.commit("setcurrentSelectDeptInfo",{})
    this.$store.commit("setallDeptData",[])
    this.$store.commit("setcommonLanguageList",{})
    this.loginInitFun();
    // document.addEventListener("mouseup",this.moseUpFn);
  },
  destroyed() {
    // document.removeEventListener("mouseup",this.moseUpFn);
    // this.removeForbidBack()
  },
   watch: {
    "ifjump": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        this.getUserInfo();
      },
    },
  },
  mounted() {
    var sign = sessionStorage.getItem("isRe")
    if(!sign) {
      sessionStorage.setItem("isRe",true);
      location.reload();
    }
  },
};
</script>

<style lang="less">
#login {
  min-height: 720px;
  display: flex;
  // background: url("../assets/imgs/login/lxh.jpg") no-repeat;
  background: url("../assets/imgs/login/bg-redsemi.png") no-repeat;
  
  background-size: 100% 100%;
  .login-box {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 500px;
    .login-main {
      margin-top: 40px;
      z-index: 5;
      width: 600px;
      height: 560px;
      box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      position: relative;
      background: url("../assets/imgs/login/abbLogin.png") no-repeat;
      background-size: 100% 100%;
      overflow: hidden;
      .login-main-title {
        font-size: 32px;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: 700;
        text-align: left;
        color: #1f1f1f;
        line-height: 40px;
        margin-left: 40px;
        margin-top: 74px;
        margin-bottom: 34px;
      }
      .login-main-input {
        width: 536px;
        height: 32px;
        margin-left: 40px;
        margin-top: 50px;
        position: relative;
        .el-input {
          // 解决回填显示浏览器自带的背景颜色
          .el-input__inner:-webkit-autofill,
          .el-input__inner:-webkit-autofill:hover,
          .el-input__inner:-webkit-autofill:focus,
          .el-input__inner:-webkit-autofill:active {
            -webkit-transition-delay: 99999s;
            -webkit-transition: color 99999s ease-out,
              background-color 99999s ease-out;
          }
          .el-input__inner {
            width: 536px;
            height: 32px;
            border-bottom: 1px solid black !important;
            border-radius: 0px;
            font-size: 16px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            padding: 0px 10px;
            border: none;
            color: #202328 !important;
            &::placeholder {
              font-size: 16px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              color: #646a74;
            }
          }
          .el-input__inner:focus {
            border-bottom: 1px solid #1c74f1 !important;
          }
        }
      }
      .login-main-button {
        position: relative;
        width: 80px;
        height: 40px;
        border-radius: 6px;
        margin-left: 40px;
        margin-top: 168px;
        background-color: #00338E;
        text-align: center;
        line-height: 42px;
        cursor: pointer;
        font-family: PingFangSC-Semibold;
        font-size: 14px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: 600;
      }
      .login-main-button::before {
        content: "";
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        background: #1c74f1;
        background-size: 400%;
        border-radius: 6px;
        z-index: -1;
        animation: sun 6s infinite;
      }
      .abbSvg {
        position: absolute;
        top: 21px;
        left: 38px;
      }
      .forget {
        position: absolute;
        left: 136px;
        bottom: 50px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #1f1f1f;
        letter-spacing: 0;
        line-height: 16px;
        font-weight: 400;
        cursor: pointer;
      }
      .remember {
        margin-top: 20px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #1f1f1f;
        letter-spacing: 0;
        line-height: 16px;
        font-weight: 400;
      }
      .titleRed {
        position: absolute;
        display: block;
        top: 60px;
        left: 40px;
        width: 45px;
        height: 2px;
        border-bottom: 4px solid #ff000f;
      }
      .clsTips {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #d50000;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 400;
      }
    }
    .reserved {
      position: absolute;
      bottom: 40px;
      margin: auto;
      font-family: ABBvoiceCNSG-Regular;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.6);
      letter-spacing: 0;
      line-height: 20px;
      font-weight: 400;
    }
  }
  .el-textarea__inner,
  .el-input__inner {
    background: transparent !important;
  }
  .el-form-item {
    margin: 0;
  }
  .el-form-item__error {
    margin-top: 4px;
    margin-left: 50px;
  }
}
</style>
